import { useEffect, useState } from "react";
import random from "../app/random";
import playAudio from "../app/playAudio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import createNotePlayers from "../app/createNotePlayers";
import BuyMeaCoffee from "./BuyMeaCoffee";
import BetaVersion from "./BetaVersion";

function ChordRecognitionPage() {
    const [notes, setNotes] = useState([]);
    const [disableNext, setDisableNext] = useState(false);
    const [disableLitenTo, setDisableListenTo] = useState(false);
    const [disableSend, setDisableSend] = useState(true);
    const [disablePlay, setDisablePlay] = useState(false);
    const [disableListenBtn, setDisableListenBtn] = useState(true);
    const [selectedChords, setSelectedChords] = useState([]);
    const [selectedInversions, setSelectedInversions] = useState([]);
    const [randomChord, setRandomChord] = useState(null);
    const [hideChords, setHideChods] = useState(false);
    const [hideInversions, setHideInversions] = useState(false);
    const [message, setMessage] = useState({
        message:"",
        correct:true
    });

    const [showChordTypes, setShowChordTypes] = useState({
        triads:false,
        sevenths:false,
        sixths:false,
        ninths:false,
        suspended:false
    });
    const [guess, setGuess] = useState({
        chord:"",
        inversion:""
    });
    const [stats, setStats] = useState({
        points: 0,
        rounds: 0
    });
    const [chords, setChords] = useState([
        {
            name: "Major triad",
            positions: [0, 4, 7],
            type:"triad"
        }, 
        {
            name: "Minor triad",
            positions: [0, 3, 7],
            type:"triad"
        }, 
        {
            name: "Diminished triad",
            positions: [0, 3, 6],
            type:"triad"
        },
        {
            name: "Augmented triad",
            positions: [0, 4, 8],
            type:"triad"
        },
        {
            name: "Major seventh",
            positions: [0, 4, 7, 11],
            type:"seventh"
        },
        {
            name: "Minor seventh",
            positions: [0, 3, 7, 10],
            type:"seventh"
        },
        {
            name: "Minor major seventh",
            positions: [0, 3, 7, 11],
            type:"seventh"
        },
        {
            name: "Dominant seventh",
            positions: [0, 4, 7, 10],
            type:"seventh"
        },
        {
            name: "Diminished seventh",
            positions: [0, 3, 6, 9],
            type:"seventh"
        },
        {
            name: "Half-diminished seventh",
            positions: [0, 3, 6, 10],
            type:"seventh"
        },
        {
            name: "Diminished-major seventh",
            positions: [0, 3, 6, 11],
            type:"seventh"
        },
        {
            name: "Augmented seventh",
            positions: [0, 4, 8, 10],
            type:"seventh"
        },
        {
            name: "Augmented-major seventh",
            positions: [0, 4, 8, 11],
            type:"seventh"
        },
        {
            name: "Major sixth",
            positions: [0, 4, 7, 9],
            type:"sixth"
        },
        {
            name: "Minor sixth",
            positions: [0, 3, 7, 9],
            type:"sixth"
        },
        {
            name: "Major ninth",
            positions: [0, 4, 7, 11, 14],
            type:"ninth"
        },
        {
            name: "Minor ninth",
            positions: [0, 3, 7, 10, 14],
            type:"ninth"
        },
        {
            name: "Dominant ninth",
            positions: [0, 4, 7, 10, 14],
            type:"ninth"
        },
        {
            name: "Minor major ninth",
            positions: [0, 3, 7, 11, 14],
            type:"ninth"
        },
        {
            name: "Diminished ninth",
            positions: [0, 3, 6, 9, 14],
            type:"ninth"
        },
        {
            name: "Half-diminished ninth",
            positions: [0, 3, 6, 10, 14],
            type:"ninth"
        },
        {
            name: "Augmented ninth",
            positions: [0, 4, 8, 10, 14],
            type:"ninth"
        },
        {
            name: "Augmented-major ninth",
            positions: [0, 4, 8, 11, 14],
            type:"ninth"
        },
        {
            name:"Suspended 2nd",
            positions:[0, 2, 7],
            type:"suspended"
        },
        {
            name:"Suspended 4th",
            positions:[0, 5, 7],
            type:"suspended"
        }
    ]);

    const showHideChordTypes = (type)=> {
        const sct = Object.create(showChordTypes);
        sct[type] = !showChordTypes[type];
        setShowChordTypes(sct);
    };

    const playaChord = ()=> {
        if(selectedChords.length === 0 || selectedInversions.length === 0) {
            alert("You have to select at least one chord and one inversion!");
            return;
        }

        setDisableNext(true);
        const chord = selectedChords[random(0, selectedChords.length-1)];
        const positions = [...chord.positions];

        let inversion = selectedInversions[random(0, selectedInversions.length-1)];

        if(chord.name === "Diminished seventh" 
        || chord.name === "Augmented triad"
        || chord.name === "Diminished ninth"
        || chord.type === "suspended") {
            inversion = {
                name:"root position",
                number:0
            };
        }

        switch (inversion.number) {
            case 1:
                positions[0] += 12;
                break;
            case 2:
                positions[0] += 12;
                positions[1] += 12;
                break;
            case 3:
                positions[0] += 12;
                positions[1] += 12;
                positions[2] += 12;
                break;
            case 4:
                positions[0] += 12;
                positions[1] += 12;
                positions[2] += 12;
                positions[3] += 12;
                break;
        }

        const maxPos = Math.max(...positions);
        const offset = random(0, notes.length - maxPos - 1);

        setRandomChord({
            name:chord.name,
            positions:positions,
            offset:offset,
            inversion:inversion.name
        });

        setDisableListenTo(true);

        setTimeout(()=>{
            setDisableListenTo(false);
        }, 1000);
        
        for(const index of positions) {
            playAudio(notes[index + offset].source);
        }
    };

    const listenToAgain = ()=> {
        if(randomChord === null)
            return;

        setDisableListenTo(true);

        setTimeout(()=>{
            setDisableListenTo(false);
        }, 1000);

        for(const index of randomChord.positions) 
            playAudio(notes[index + randomChord.offset].source);
    };

    const deselectAllBtns = (cls)=>{
        const btns = document.querySelectorAll(`.${cls}`);

        for(const btn of btns) {
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-secondary");
        }
    };

    const guessChord = (btn)=> {
        if(randomChord === null) {
            alert("You have to press the 'listen' button first!");
            return;
        } 

        deselectAllBtns("chord-guess");

        btn.classList.toggle("btn-primary");
        btn.classList.toggle("btn-secondary");
        if(btn.classList.contains("btn-primary"))
            setGuess(g=>({...guess, chord:btn.innerText.trim()}));
        else 
            setGuess(g=>({...guess, chord:""}));
    };

    const guesInversion = (btn)=> {
        if(randomChord === null) {
            alert("You have to press the 'listen' button first!");
            return;
        }

        deselectAllBtns("inversion-guess");

        btn.classList.toggle("btn-primary");
        btn.classList.toggle("btn-secondary");

        if(btn.classList.contains("btn-primary"))
            setGuess(g=>({...g, inversion:btn.innerText.trim()}));
        else 
            setGuess(g=>({...g, inversion:""}));
    };

    const sendGuess = ()=> {
        setStats((s) => ({ ...s, rounds: s.rounds + 1 }));

        if(guess.chord === randomChord.name
        && guess.inversion === randomChord.inversion) {
            setStats((s) => ({ ...s, points: s.points + 1 }));

            setMessage({
                message:"That's correct. Congratulations!",
                correct:true
            });
        } else {
            setMessage({
                message:`The correct answer is: ${randomChord.name} (${randomChord.inversion})`,
                correct:false
            });
        }

        setDisableNext(false);
        setDisableListenTo(true);
        setDisableSend(true);
        deselectAllBtns("chord-guess");
        deselectAllBtns("inversion-guess");
        setGuess({
            chord:"",
            inversion:""
        });

        setRandomChord(null);
    };

    useEffect(()=> {
        if(guess.chord.length !== 0 && guess.inversion.length !== 0) {
            setDisableSend(false);
        } else {
            setDisableSend(true);
        }
    }, [guess]);

    const selectChord = (chord, btn)=> {
        btn.classList.toggle("btn-primary");
        btn.classList.toggle("btn-mid-grey");
        const index = selectedChords.findIndex(sc=>sc.name === chord.name);

        if(index === -1) {
            setSelectedChords([...selectedChords, chord]);
        } else {
            const scs = [...selectedChords];
            scs.splice(index, 1);
            setSelectedChords(scs);
        }
    };

    const playNoteByNote = ()=> {
        if(randomChord === null) {
            alert("You have press the 'listen' button first!");
            return;
        }

        const sortedNotes = randomChord.positions.sort((a, b)=>a-b);
        notes[sortedNotes[0] + randomChord.offset].player.play();
        let index = 1;

        const intervalID = setInterval(()=> {
            notes[sortedNotes[index] + randomChord.offset].player.play();
            index++;

            if(index === sortedNotes.length) {
                clearInterval(intervalID);
            }
        }, 800);
    };

    const tellMe = ()=> {
        if(randomChord === null)
            return;

        alert(`The correct answer is: ${randomChord.name} (${randomChord.inversion})`);
    };

    const addInversion = (btn, inversionNumber)=> {
        if(selectedChords.length === 0) {
            alert(`Please, select at least one chord!`);
            return;
        }

        const inversion = btn.innerText.trim();
        const containsSevenths = selectedChords.some(sc=>sc.type === "seventh" 
        && sc.name !== "Diminished seventh");
        const containsNinths = selectedChords.some(sc=>sc.type === "ninth" 
        && sc.name !== "Diminished ninth");

        const onlyInversionlessChords = 
        selectedChords.every(sc=>sc.type === "suspended" 
        || sc.name === "Augmented triad"
        || sc.name === "Diminished seventh"
        || sc.name === "Diminished ninth");

        if(inversion !== "root position" && onlyInversionlessChords) {
            alert(`You cannot select inversions in cases of 
            suspended chords, augmented triad 
            or diminished seventh and diminished ninth!`);
            return;
        }

        if(inversion === "3rd inversion" 
        && !containsSevenths && !containsNinths) {
            alert("Triads doesn't have 3rd inversion!");
            return;
        }

        if(inversion === "4th inversion" && !containsNinths) {
            alert("Triads and sevenths doesn't have 4th inversion!");
            return;
        }
        
        const index = selectedInversions.findIndex(si=>si.name === inversion);
        btn.classList.toggle("btn-primary");
        btn.classList.toggle("btn-mid-grey");

        if(index === -1)
            setSelectedInversions([...selectedInversions, {name:inversion, number:inversionNumber}]);
        else {
            const sis = [...selectedInversions];
            sis.splice(index, 1);
            setSelectedInversions(sis);
        }
    };

    useEffect(()=> {
        createNotePlayers(setNotes, "piano");
    }, []);

    useEffect(()=> {
        if(selectedChords.length === 0 || selectedInversions.length === 0)
            setDisableListenBtn(true);
        else 
            setDisableListenBtn(false);
    }, [selectedChords, selectedInversions]);

    useEffect(()=> {
        setHideChods(randomChord === null);
        setHideInversions(randomChord === null);
    }, [randomChord]);

    return(
        <div className="container-lg text-center">
            <h1>Chord Recognition Quiz</h1>
            
            <BuyMeaCoffee/>
            
            <div className="row">
                <div className="col-lg-4 box-light-grey p-xl table-border">
                    <h3>Select chords!</h3>

                    <h4>Triads</h4>
                    <div className="box-secondary color-white cursor-pointer" onClick={()=>showHideChordTypes("triads")}>
                        <FontAwesomeIcon icon={!showChordTypes.triads ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"}/>
                    </div>
                    <div className={"row " + (!showChordTypes.triads && "d-none")}>
                        {
                            chords.filter(ch=>ch.type==="triad").map((chord, i) =>
                                <div key={i} className="col-lg-6 col-md-3 col-sm-4 col-xs-4">
                                    <button key={i} onClick={(e)=>selectChord(chord, e.target)}
                                    className="btn-sm btn-mid-grey center-input minw-30 
                                    mw-100 note-select-btn btn-fix-md">{chord.name}</button>
                                </div>
                            )
                        }
                    </div>

                    <h4>Seventh chords</h4>
                    <div className="box-secondary color-white cursor-pointer" onClick={()=>showHideChordTypes("sevenths")}>
                        <FontAwesomeIcon icon={!showChordTypes.sevenths ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"} />
                    </div>

                    <div className={"row " + (!showChordTypes.sevenths && "d-none")}>
                        {
                            chords.filter(ch=>ch.type==="seventh").map((chord, i) =>
                                <div key={i} className="col-lg-6 col-md-3 col-sm-4 col-xs-4">
                                    <button key={i} onClick={(e)=>selectChord(chord, e.target)}
                                    className="btn-sm btn-mid-grey center-input minw-30 
                                    mw-100 note-select-btn btn-fix-md">{chord.name}</button>
                                </div>
                            )
                        }
                    </div>

                    <h4>Sixth chords</h4>
                    <div className="box-secondary color-white cursor-pointer" onClick={()=>showHideChordTypes("sixths")}>
                        <FontAwesomeIcon icon={!showChordTypes.sixths ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"} />
                    </div>

                    <div className={"row " + (!showChordTypes.sixths && "d-none")}>
                        {
                            chords.filter(ch=>ch.type==="sixth").map((chord, i) =>
                                <div key={i} className="col-lg-6 col-md-3 col-sm-4 col-xs-4">
                                    <button key={i} onClick={(e)=>selectChord(chord, e.target)}
                                    className="btn-sm btn-mid-grey center-input minw-30 
                                    mw-100 note-select-btn btn-fix-md">{chord.name}</button>
                                </div>
                            )
                        }
                    </div>

                    <h4>Ninth chords</h4>
                    <div className="box-secondary color-white cursor-pointer" onClick={()=>showHideChordTypes("ninths")}>
                        <FontAwesomeIcon icon={!showChordTypes.ninths ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"} />
                    </div>

                    <div className={"row " + (!showChordTypes.ninths && "d-none")}>
                        {
                            chords.filter(ch=>ch.type==="ninth").map((chord, i) =>
                                <div key={i} className="col-lg-6 col-md-3 col-sm-4 col-xs-4">
                                    <button key={i} onClick={(e)=>selectChord(chord, e.target)}
                                    className="btn-sm btn-mid-grey center-input minw-30 
                                    mw-100 note-select-btn btn-fix-md">{chord.name}</button>
                                </div>
                            )
                        }
                    </div>

                    <h4>Suspended chords</h4>
                    <div className="box-secondary color-white cursor-pointer" onClick={()=>showHideChordTypes("suspended")}>
                        <FontAwesomeIcon icon={!showChordTypes.suspended ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"}/>
                    </div>

                    <div className={"row " + (!showChordTypes.suspended && "d-none")}>
                        {
                            chords.filter(ch=>ch.type==="suspended").map((chord, i) =>
                                <div key={i} className="col-lg-6 col-md-3 col-sm-4 col-xs-4">
                                    <button key={i} onClick={(e)=>selectChord(chord, e.target)}
                                    className="btn-sm btn-mid-grey center-input minw-30 
                                    mw-100 note-select-btn btn-fix-md">{chord.name}</button>
                                </div>
                            )
                        }
                    </div>

                    <h3>Select inversions!</h3>
                    <div className="row jc-center p-sm wp-100 bg-white mt-lg minh-75 rounded-5">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex jc-center">
                            <button onClick={(e)=>addInversion(e.target, 0)}
                            className="btn-sm btn-mid-grey minw-100 inversion-select">root position</button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex jc-center">
                            <button onClick={(e)=>addInversion(e.target, 1)}
                            className="btn-sm btn-mid-grey minw-100 inversion-select">1st inversion</button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex jc-center">
                            <button onClick={(e)=>addInversion(e.target, 2)}
                            className="btn-sm btn-mid-grey minw-100 inversion-select">2nd inversion</button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex jc-center">
                            <button onClick={(e)=>addInversion(e.target, 3)}
                            className="btn-sm btn-mid-grey minw-100 inversion-select">3rd inversion</button>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex jc-center">
                            <button onClick={(e)=>addInversion(e.target, 4)}
                            className="btn-sm btn-mid-grey minw-100 inversion-select">4th inversion</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 p-xl box-light-grey table-border">
                    <h2>Guess the chord!</h2>
                    <BetaVersion/>
                    <h3>Statistics</h3>
                    <div className={"bold-text mb-lg " + (message.correct ? "color-primary" : "color-warning")}>
                        {message.message}
                    </div>

                    <div className="row wp-100 color-white bold-text rounded-5">
                        <div className="box-primary col-lg-6 col-md-6 col-sm-6 col-xs-6 table-border p-md">
                            rounds: {stats.rounds}
                        </div>
                        <div className="box-primary col-lg-6 col-md-6 col-sm-6 col-xs-6 table-border p-md">
                            points: {stats.points}
                        </div>
                    </div>

                    <h3>Choose your guess (chord)!</h3>
                    <div className="row jc-center p-sm wp-100 bg-white mt-lg minh-75 rounded-5 position-relative">
                        <div className={"modal " + (!hideChords && "d-none")}></div>
                        {
                            selectedChords.map((chord, i) =>
                                <div key={i} className="col-lg-2 col-md-3 col-sm-4 col-xs-6 p-sm d-flex jc-center">
                                    <button onClick={(e)=>guessChord(e.target)}
                                    className="btn-sm btn-secondary minw-30 
                                    note-btn btn-fix-md chord-guess">{chord.name}</button>
                                </div>
                            )
                        }
                    </div>

                    <h3>Choose your guess! (inversion)!</h3>
                    <div className="row jc-center p-sm wp-100 bg-white mt-lg minh-75 rounded-5 position-relative">
                        <div className={"modal " + (!hideInversions && "d-none")}></div>
                        {
                            selectedInversions.map((si, i)=> 
                                <div key={i} className="col-lg-2 col-md-2 col-sm-2 col-xs-6 d-flex jc-center">
                                    <button onClick={(e)=>guesInversion(e.target, si.number)}
                                    className="btn-sm btn-secondary inversion-guess">{si.name}</button>
                                </div>
                            )
                        }
                    </div>

                    {
                        !disableNext ?
                            <button onClick={playaChord} disabled={disableListenBtn}
                                className={"btn-md btn-primary center-input " 
                                + (disableListenBtn && "btn-mid-grey")}>Listen</button>
                            : 
                            <div className="d-flex jc-center">
                                <button onClick={listenToAgain} disabled={disableLitenTo}
                                className={"btn-md btn-primary m-lg " + 
                                (disableLitenTo && "btn-mid-grey")}>Listen to again!</button>

                                <button disabled={disableSend} onClick={sendGuess}
                                className={"btn-md btn-primary m-lg " 
                                + (disableSend && "btn-mid-grey")}>Send!</button>
                            </div>
                    }

                    <h2>Help me, please!</h2>
                    <div className="row mt-xl">
                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Tell me!</h3>
                            <button onClick={tellMe}
                            className="btn-md btn-error center-input">Tell me!</button>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 box-white p-md table-border">
                            <h3>Play note-by-note!</h3>
                            <button onClick={playNoteByNote} className={"btn-md center-input " 
                            + (disablePlay ? "btn-mid-grey" : "btn-error")}>Play!</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ChordRecognitionPage;