import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BuyMeaCoffee from "./BuyMeaCoffee";
import BetaVersion from "./BetaVersion";

function HomePage() {
    return (
        <div className="container-lg text-center box-light-grey p-md">
            <h1>Welcome to Ear Genius!</h1>
            <BetaVersion/>
            
            <p>
                My name is Olivér Kovács, I'm a Hungarian composer and programmer.
                I designed this site to develop your ears and improve your musical abilities,
                like interval, scale, and chord recognition.  <br/>

                Recognizing scales, intervals, and chords by listening is crucial to being a
                better musician. These abilities help you to compose better music, and of
                course, it's important to understand basic music theory well. <br />
                <span className="bold-text">
                    Practice every day a little bit, and you'll be a better musician!
                </span>
            </p>

            <BuyMeaCoffee/>

            <div className="p-md">
                <h2>Link tree</h2>

                <div className="mw-500 margin-auto">
                    <h4 className="font-22">
                        <a target="_blank" style={{ textDecoration: "none" }} href="https://www.youtube.com/@ComposersCompassYT">
                            <FontAwesomeIcon className="color-secondary mr-sm"
                                icon="fa-brands fa-youtube" />

                            <span className="color-secondary-lighter">Composer's Compass YouTube</span>
                        </a>
                    </h4>

                    <h4 className="font-22">
                        <a target="_blank" style={{ textDecoration: "none" }} href="https://buymeacoffee.com/composerscompass">
                            <FontAwesomeIcon className="color-secondary mr-sm"
                                icon="fa-solid fa-mug-hot" />

                            <span className="color-secondary-lighter">Please, buy me a coffee!</span>
                        </a>
                    </h4>

                    <h4 className="font-22">
                        <a target="_blank" style={{ textDecoration: "none" }} href="https://www.facebook.com/composerscompass">
                            <FontAwesomeIcon className="color-secondary mr-sm"
                                icon="fa-brands fa-facebook" />

                            <span className="color-secondary-lighter">Composer's Compass Facebook</span>
                        </a>
                    </h4>

                    <h4 className="font-22">

                        <a target="_blank" href="https://oliverkovacs.eu" style={{ textDecoration: "none" }} >
                            <FontAwesomeIcon className="color-secondary mr-sm"
                                icon="fa-solid fa-house" />

                            <span className="color-secondary-lighter">My webpage</span>
                        </a>
                    </h4>

                </div>
            </div>
        </div>
    );
}

export default HomePage;