import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function BuyMeaCoffee() {
    return(
        <p className="bold-text">
            If you find this webpage helpful, please consider buying me a coffee!<br/>
            <a target="_blank" style={{ textDecoration: "none" }} href="https://buymeacoffee.com/composerscompass">
                <FontAwesomeIcon className="color-secondary mr-sm"
                    icon="fa-solid fa-mug-hot" />

                <span className="color-secondary-lighter">Click here to donate!</span>
            </a>
        </p>
    );
}

export default BuyMeaCoffee;